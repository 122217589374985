import { ColorEnum } from '@core/enum/common';

export const Colors = [
  {
    value: ColorEnum.GRAY,
    primaryColor: '#758195',
    textColor: '#44546F',
    bgColor: '#F1F2F4'
  },
  {
    value: ColorEnum.RED,
    primaryColor: '#DC2626',
    textColor: '#991B1B',
    bgColor: '#FEE2E2'
  },
  {
    value: ColorEnum.ORANGE,
    primaryColor: '#EA580C',
    textColor: '#9A3412',
    bgColor: '#FFEDD5'
  },
  {
    value: ColorEnum.GREEN,
    primaryColor: '#16A34A',
    textColor: '#166534',
    bgColor: '#DCFCE7'
  },
  {
    value: ColorEnum.BLUE_SKY,
    primaryColor: '#0891B2',
    textColor: '#155E75',
    bgColor: '#CFFAFE'
  },
  {
    value: ColorEnum.BLUE,
    primaryColor: '#2563EB',
    textColor: '#1E40AF',
    bgColor: '#DBEAFE'
  },
  {
    value: ColorEnum.PURPLE,
    primaryColor: '#7C3AED',
    textColor: '#5B21B6',
    bgColor: '#EDE9FE'
  },
  {
    value: ColorEnum.PINK,
    primaryColor: '#DB2777',
    textColor: '#9D174D',
    bgColor: '#FCE7F3'
  },
  {
    value: ColorEnum.YELLOW,
    primaryColor: '#D19500',
    textColor: '#89530A',
    bgColor: '#FFFEC1'
  }
];
